import { render, staticRenderFns } from "./index.vue?vue&type=template&id=81a0b55a&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=81a0b55a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "81a0b55a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Hero: require('/Users/tdrdimov/gogoproj/fitapp - current GIT/fitnhealthy/src/components/Hero.vue').default,SubscriptionCard: require('/Users/tdrdimov/gogoproj/fitapp - current GIT/fitnhealthy/src/components/SubscriptionCard.vue').default,BaseAccordion: require('/Users/tdrdimov/gogoproj/fitapp - current GIT/fitnhealthy/src/components/BaseAccordion.vue').default})
