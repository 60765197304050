
import BaseAccordion from '../components/BaseAccordion.vue'

export default {
  name: 'home-page',
  layout: 'server-render',
  components: {
    BaseAccordion
  },
  watchQuery: ['page'],
  transition(to, from) {
    if (!from) return 'fade'
    return +to.query.page > +from.query.page ? 'slide-right' : 'slide-left'
  },
  head() {
    return {
      title: 'FitnHealthy - Shape Up in 4 Weeks: Effective Programs to get you Fit and Healthy',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content:
            'Transfer Your body and health in just 4 weeks with our science-based designed fitness programs'
        }
      ],
      link: [
        {
          rel: 'canonical',
          href: 'https://fitnhealthy.com/'
        },
        {
          rel: 'preconnect',
          href: 'https://fonts.gstatic.com',
          crossorigin: true
        },
        {
          rel: 'stylesheet',
          href: 'https://fonts.googleapis.com/css2?family=Oswald:wght@400;500;600;700&display=swap'
        },
        {
          rel: 'stylesheet',
          href: 'https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap'
        },
        {
          rel: 'preload',
          href: this.heroVideo,
          as: 'video/mp4',
          type: 'video/mp4'
        }
      ]
    }
  },
  data() {
    return {
      heroVideo: 'https://firebasestorage.googleapis.com/v0/b/inspireforhealthy-13a40.appspot.com/o/programsLanding%2Fhome%2Fhero-bg.mp4?alt=media&token=2f28cae7-7c28-4678-83a8-d34fb7fe6122',
      crsl: 0,
      isLoading: false,
      how_it_works: {
        title: 'Everything You Need in One App',
        img: 'https://firebasestorage.googleapis.com/v0/b/inspireforhealthy-13a40.appspot.com/o/programsLanding%2F4-week-lifestyle-transformation%2Ffitnhealthy_gif.gif?alt=media&token=1aee1429-c99b-4e28-b696-cacbd395ad60',
        steps: [
          {
            title: 'Home and Gym Workouts:',
            text: 'Follow a structured science based training program suited to your goals and training experience with guided workouts, with options for both home and gym options from level 1 beginner to level 4 advanced.'
          },
          {
            title: 'Science-Based Nutrition:',
            text: 'Meal guides designed for your personal goals and preferences. Explore nutritious recipes, grocery finds, so you can enjoy more of the foods you love. Your nutrition program comes with dietary tips based on the latest research to support your fitness goals.'
          },
          {
            title: 'Community:',
            text: 'Our community is a safe place for you to share your journey and experiences, connect with other team members, join challenges, and find motivation with others. I will also be there to help and support you in any way I can during your FitnHealthy journey. Let’s get Fit and be Healthy together as a team.'
          }
        ]
      },
      subscriptions: [
        {
          type: '1 on 1 Online Training',
          program: 'online-training',
          learnMore: '/start-today',
          image: '/images/home/9.jpg',
          features: [
            {
              icon: '/images/home/calendar.png',
              text: 'No Time Limit'
            },
            {
              icon: '/images/home/dumbbells.png',
              text: 'Home/Gym'
            },
            {
              icon: '/images/home/fullbody.png',
              text: 'Custom Plan'
            },
            {
              icon: '/images/home/meal.png',
              text: 'Meal Guide'
            }
          ]
        },
        {
          type: '4 Week FitnHealthy Program',
          image: '/images/home/8.jpg',
          program: '4-week-fitnhealthy',
          highlighted: true,
          learnMore: '/programs/4-week-fitnhealthy',
          features: [
            {
              icon: '/images/home/calendar.png',
              text: '4 Weeks'
            },
            {
              icon: '/images/home/dumbbells.png',
              text: 'Home/Gym'
            },
            {
              icon: '/images/home/workout.png',
              text: 'Get in Shape'
            },
            {
              icon: '/images/home/meal.png',
              text: 'Meal Guide'
            }
          ]
        },
        {
          type: '4 Week Booty Program',
          image: '/images/home/10.jpeg',
          program: '4-week-booty-program',
          learnMore: '/programs',
          features: [
            {
              icon: '/images/home/calendar.png',
              text: '4 Weeks'
            },
            {
              icon: '/images/home/dumbbells.png',
              text: 'Home/Gym'
            },
            {
              icon: '/images/home/body.png',
              text: 'Build Booty'
            },
            {
              icon: '/images/home/meal.png',
              text: 'Meal Guide'
            }
          ]
        }
      ],
      faqs: [
        {
          question: 'How does 1 on 1 online training work?',
          answer:
            "1 on 1 online training works by providing you with a customized fitness and nutrition program based on your body, health goals, and level of fitness to help you reach your goals faster. You'll have a monthly virtual session to discuss your progress and make any necessary adjustments. Your program is updated every four weeks, but changes are made weekly when needed. You can also text me or set up a call anytime if you have questions. All changes will be made through your FitnHealthy account."
        },
        {
          question: 'What is included in the 1 on 1 Online Training Subscription?',
          answer:
            'Your program will be delivered completely through the FitnHealthy App! It incorporates your Meal Guide, Food Swap Table, Training Plan, Cardio Plan, Supplement List, All Check-Ins, Exercise Video Demonstrations… Everything you need is in the app! It makes it so simple to stay on track as everything you need is in the palm of your hand. We will have a consultation once you sign up so I can start customizing your plan right after. You will also have my personal cell number to keep in touch with me anytime, and I will reach out to you as soon as I can each time.'
        },
        {
          question: 'Do I need gym equipment for 1 on 1 online training?',
          answer:
            'No problem! You can choose between home or gym program. All your workouts will be customized based on the type of equipment you have at home or the gym. If you get new equipment, don’t forget to mention it during your check-in so I can add workouts with that equipment. If you will be traveling or need a certain week with no equipment, also let me know and I will make the changes. Please note, you will still need some equipment for home workouts. I recommend at least a stability ball if you are a beginner and a pair of dumbbells. We can start with no equipment, but by week 3, I do recommend getting those items.'
        },
        {
          question: 'How do 4-week programs work?',
          answer:
            "The 4-week programs start every Monday following purchase, ensuring consistent support and guidance. Upon completing the purchase, you'll gain access to your FitnHealthy account and receive a welcome email to prepare you for your fitness and nutrition program. Additionally, you'll be part of a community where you can interact with fellow members, seek advice, and share experiences."
        },
        {
          question: 'Do I need Gym equipment for any of the 4 week programs?',
          answer:
            'For some programs you can do right at home with just a pair of dumbbells and bands, but I do suggest you to click on “learn more” to find out if the program requires more than a pair of dumbbells.'
        },
        {
          question: 'Can I lose fat and build muscle during any of the 4 week programs?',
          answer:
            'Yes! Everything comes down to your diet and every program will include a nutrition program that will teach you how to eat for weight loss, building muscle or if your goal is both.'
        }
      ],
      before_after: [
        {
          img: 'https://firebasestorage.googleapis.com/v0/b/inspireforhealthy-13a40.appspot.com/o/programsLanding%2F4-week-lifestyle-transformation%2Fjamie.webp?alt=media&token=ac83efed-a14a-407d-b67f-a35e6a27fcd5'
        },
        {
          img: 'https://firebasestorage.googleapis.com/v0/b/inspireforhealthy-13a40.appspot.com/o/programsLanding%2F4-week-lifestyle-transformation%2Fbriana_beforeAfter.webp?alt=media&token=925f9dab-8ae4-4997-9c20-13aea98a5d3e'
        },
        {
          img: 'https://firebasestorage.googleapis.com/v0/b/inspireforhealthy-13a40.appspot.com/o/programsLanding%2F4-week-lifestyle-transformation%2Ferik.webp?alt=media&token=c628f6e8-4df8-45f8-b0c1-8393d194f36b'
        },
        {
          img: 'https://firebasestorage.googleapis.com/v0/b/inspireforhealthy-13a40.appspot.com/o/programsLanding%2F4-week-lifestyle-transformation%2Feve.jpg?alt=media&token=c840d165-3446-4c7e-a853-b8a4419cf878'
        },
        {
          img: 'https://firebasestorage.googleapis.com/v0/b/inspireforhealthy-13a40.appspot.com/o/programsLanding%2F4-week-lifestyle-transformation%2Fshelly.jpg?alt=media&token=b2651626-9cb1-49be-909e-c546ba65295b'
        },
        {
          img: 'https://firebasestorage.googleapis.com/v0/b/inspireforhealthy-13a40.appspot.com/o/programsLanding%2F4-week-lifestyle-transformation%2Fshelly-front.jpg?alt=media&token=4ddb7c64-f8ad-4e49-9d0c-aa9f2fb8e007'
        },
        {
          img: 'https://firebasestorage.googleapis.com/v0/b/inspireforhealthy-13a40.appspot.com/o/programsLanding%2F4-week-lifestyle-transformation%2Fshelly-back.jpg?alt=media&token=509caf23-564a-4fa0-9608-b30b6249c070'
        },
        {
          img: 'https://firebasestorage.googleapis.com/v0/b/inspireforhealthy-13a40.appspot.com/o/programsLanding%2F4-week-lifestyle-transformation%2Fclient1.jpg?alt=media&token=bc073a59-8eb8-4286-b5c0-fe06e877cbda'
        },
        {
          img: 'https://firebasestorage.googleapis.com/v0/b/inspireforhealthy-13a40.appspot.com/o/programsLanding%2F4-week-lifestyle-transformation%2Fclient2.jpg?alt=media&token=842d750b-3710-4147-99c4-bee9b95ad7d4'
        },
        {
          img: 'https://firebasestorage.googleapis.com/v0/b/inspireforhealthy-13a40.appspot.com/o/programsLanding%2F4-week-lifestyle-transformation%2Fclient3.jpg?alt=media&token=e818c9f4-0b3d-4b65-bc1d-04a06ed00ccd'
        },
        {
          img: 'https://firebasestorage.googleapis.com/v0/b/inspireforhealthy-13a40.appspot.com/o/programsLanding%2F4-week-lifestyle-transformation%2Fclient4.jpg?alt=media&token=5e9094dc-edfc-4f80-9c16-6bf0291a657a'
        },
        {
          img: 'https://firebasestorage.googleapis.com/v0/b/inspireforhealthy-13a40.appspot.com/o/programsLanding%2F4-week-lifestyle-transformation%2Fclient5.jpg?alt=media&token=d85c8af5-9a2b-46e9-a632-9c6aed65e0a4'
        },
        {
          img: 'https://firebasestorage.googleapis.com/v0/b/inspireforhealthy-13a40.appspot.com/o/programsLanding%2F4-week-lifestyle-transformation%2Fclient6.jpg?alt=media&token=ab052c83-da6b-4fbb-a940-4efbbd620cb9'
        }
      ]
    }
  },
  mounted() {
    const routeHash = this.$route.hash
    if (routeHash) {
      const element = document.querySelector(routeHash)

      setTimeout(() => {
        element.scrollIntoView({
          block: 'start',
          inline: 'nearest',
          behavior: 'smooth'
        })
      }, 500)
    }
  },
  methods: {
    scrollMeTo(refName) {
      const element = document.getElementById(refName)
      const top = element.offsetTop
      if (process.browser) {
        window.scrollTo({
          left: 0,
          top: top - 50,
          behavior: 'smooth'
        })
      }
    }
  }
}
