
export default {
  name: 'BaseAccordion',
  props: {
    selWeek: String,
    id: Number,
    openId: Number,
    completed: String,
    open: Boolean
  },
  data() {
    return {
      show: false
    }
  },
  methods: {
    toggle() {
      this.show = !this.show
      this.$emit('opened')
    }
  },
  mounted() {
    if (this.open) {
      this.show = this.open
    }
  },
  watch: {
    completed() {
      this.show = false
    },
    selWeek() {
      this.show = false
    },
    openId(nVal) {
      if (nVal === this.id) {
        this.show = true
      } else {
        this.show = false
      }
    }
  }
}
