
export default {
  name: 'hero-section',
  props: {
    video: String
  },
  mounted() {
    const heroVideo = this.$refs['hero-video']
    if (!heroVideo) return
    const observer = new IntersectionObserver(
      (entries, obsrv) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            heroVideo.setAttribute('preload', 'auto')
            heroVideo.load()
            heroVideo.play()
            obsrv.unobserve(heroVideo)
          }
        })
      },
      { threshold: 0.5 }
    )
    observer.observe(heroVideo)

    heroVideo.addEventListener('loadedmetadata', () => {
      heroVideo.currentTime = 5 // Start the video at the 5th second
    })
    heroVideo.playbackRate = 0.7
  },
  methods: {
    scrollMeTo(refName) {
      const element = document.getElementById(refName)
      const top = element.offsetTop
      if (process.browser) {
        window.scrollTo({
          left: 0,
          top: top - 50,
          behavior: 'smooth'
        })
      }
    }
  }
}
