
import DropDownMenuBtn from './vuetify/DropDownMenuBtn.vue'

export default {
  components: {
    DropDownMenuBtn
  },
  props: {
    cardData: Object
  },
  data() {
    return {
      stripeProduct: null
    }
  },
  methods: {
    slugify(text) {
      return text
        .toLowerCase()
        .replace(/[^\w ]+/g, '')
        .replace(/ +/g, '-')
    },
    async buyProgramRoute(prod) {
      let { program } = this.cardData
      if (prod.product) {
        this.$store.commit('ADD_CART', prod)
        program = this.slugify(prod.product.name)
      } else {
        /* eslint-disable-next-line */
        if (this.stripePrograms[program]) {
          if (
            !this.cartProd
            || (this.cartProd && this.cartProd.product.id !== this.stripePrograms[program])
          ) {
            await this.$store.dispatch('getSingleProductStripe', this.stripePrograms[program])
          }
        }
      }
      if (this.user) {
        this.$router.push('/checkout')
      } else {
        this.$router.push(`/register?program=${program}`)
      }
      if (!this.cartProd) {
        this.$router.push('/programs')
      }
    }
  },
  async mounted() {
    if (!this.products || !this.products.length) {
      await this.$store.dispatch('getProductsStripe')
    }
    const programs = this.products.filter(
      (prod) => prod.price.type === 'one_time'
        && prod.product.metadata.store_prod
        && this.slugify(prod.product.name).includes(this.cardData.program)
    )
    this.stripeProduct = programs
  },
  computed: {
    products() {
      return this.$store.state.stripeProds
    },
    cartProd() {
      return this.$store.state.cart[0]
    },
    user() {
      return this.$store.state.user
    },
    stripePrograms() {
      return this.$store.state.stripePrograms
    }
  }
}
