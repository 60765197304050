
export default {
  props: {
    items: Array,
    buttonTitle: String
  },
  data() {
    return {}
  },
  methods: {
    handleClick(item) {
      this.$emit('onClick', { product: item.product, price: item.price })
    }
  }
}
